import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import React from 'react';

const Article = ({ data }) => {
  const { frontmatter, body } = data.mdx;

  return (
    <Layout isFooterRed={true}>
      <div  style={{
        margin: '100px auto 0 auto',
        maxWidth: 720,
        padding: '0 10px',
        backgroundColor: 'rgba(255,255,255, 0.5)',
      }}>
        {/*<Dump next={next} />*/}
        <h1>{frontmatter.title}</h1>
        <p>{frontmatter.date}</p>
        <div style={{ textAlign: "justify" }}>
          <MDXRenderer localImages={frontmatter.embeddedImagesLocal}>
            {body}
          </MDXRenderer>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx( slug: { eq: $slug } ) {
      body
      frontmatter {
        date(formatString: "YYYY MMMM Do")
        title
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default Article;